import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const Contact = props => {
  const contact = props.data.contact;
  console.log(contact);

  return (
    <Layout bodyClass="page-default-single">
      <SEO title="Contact" />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <h1 className="title">{contact.frontmatter.title}</h1>
            <Call showButton={false} />
            <div className="content mt-4" dangerouslySetInnerHTML={{ __html: contact.html }} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    contact: markdownRemark(
      fileAbsolutePath: {regex: "/content/contact.md/"}
    ) {
        html
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
        }
    }
  }
`;

export default Contact;